import { Button, DrawerBodyWrapper, DrawerFooterWrapper, RadioButton, Textarea } from '@base'
import { strings } from '@constants'
import React, { useState } from 'react'
import { FINAL_LEAD_STATUS } from '@constants'
import { updateLeadStatus } from '@data/conn/put/leads'
import { useDispatch, useSelector } from 'react-redux'
import { toggleDrawer } from '@data/state/action/root'
import styled from 'styled-components'
import { Spacings } from '@styles'

const RadioButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
	padding: ${Spacings.SPACING_2B} 0;
`

const Label = styled.label`
	font-size: ${Text.MEDIUM};
	margin-left: ${Spacings.SPACING_2B};
	color: ${({ theme }) => theme.palette.text.black};
	font-weight: ${Text.BOLD_600};
	cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
	user-select: none;
`

const SectionWrapper = styled.div`
	display: flex;
	flex-direction: column;
`

const StatusWrapper = styled.div`
	display: flex;
	margin-top: ${Spacings.SPACING_3B};
`
// const finalLeadsStatus=Object.entries(FINAL_LEAD_STATUS).map(([key,value])=>({key,label:value}))

const CompleteLead = ({ leads, update }) => {
	const [status, setStatus] = useState(FINAL_LEAD_STATUS.WON)
	const [note, setNote] = useState('')
	const dispatch = useDispatch()
	const user = useSelector((state) => state.getIn(['user', 'user']))
	const editor = user.getUserDetails()

	const submitHandler = () => {
		let notes = leads?.notes || []
		let _note = { editor: editor, text: note, date: new Date().getTime() }
		notes.push(_note)
		// updateLead({ ...leads, status: status, notes: notes })
		// 	.then(() => {
		// 		update()
		// 	})
		// 	.catch((err) => {
		// 		console.log(err)
		// 	})
		// 	.finally(() => {
		// 		dispatch(toggleDrawer(false))
		// 	})
		updateLeadStatus(leads?.lead_id, status, _note)
			.then((response) => {
				// console.log(response)
				update()
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				dispatch(toggleDrawer(false))
			})
	}
	return (
		<>
			<DrawerBodyWrapper>
				<SectionWrapper>
					<div>{strings(['Select', 'Status'])}</div>
					<StatusWrapper>
						<RadioButtonWrapper>
							<RadioButton
								id='won-radio'
								size='1.125'
								checked={status === FINAL_LEAD_STATUS.WON}
								value={FINAL_LEAD_STATUS.WON}
								onChange={() => {
									setStatus(FINAL_LEAD_STATUS.WON)
								}}
							/>
							<Label htmlFor='intention-radio'>WON </Label>
						</RadioButtonWrapper>
						<RadioButtonWrapper>
							<RadioButton
								id='ability-radio'
								size='1.125'
								checked={status === FINAL_LEAD_STATUS.LOST}
								value={FINAL_LEAD_STATUS.LOST}
								onChange={() => {
									setStatus(FINAL_LEAD_STATUS.LOST)
								}}
							/>
							<Label htmlFor='ability-radio'>LOST </Label>
						</RadioButtonWrapper>
					</StatusWrapper>
				</SectionWrapper>
				{/* <Select
					id='status'
					placeholder={strings('status')}
					label={strings('select', 'status')}
					value={status}
					data={finalLeadStatus}
					displayKey='key'
					primaryKey='key'
					onChange={setStatus}
				/> */}
				<Textarea id='notes' type='text' rows={5} placeholder={strings(['enter', 'note', '...'])} value={note} onChange={setNote} />
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button onClick={submitHandler} small disabled={!note || !status}>
					{strings('complete')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default CompleteLead
