const deleteUrl = {
	deleteEnquiry: '/enquiry',
	deleteOrder: '/order',
	deleteAuction: '/auction',
	deleteAuctionOrder: '/order',
	deleteLedgerByDocumentId: '/ledger/deleteLedgerByDocumentId',
	deleteBillInvoice: '/invoice',
	deleteOrderDocument: '/files/deleteDocument',
	deleteContent: '/content',
	deleteCommodity: '/commodity',
	deleteFactoryInvoice: '/factory/deleteFabricationInvoice',
	deleteUser: '/organisation/deleteUser',
	deleteUnregisteredUser: 'user/deleteUnregisteredUser',
	deleteEnquiryPhoto: '/enquiryPhoto',
	deleteUserWithOrg: '/extra/deleteUserWithOrg',
	deleteAdmin: '/user/deleteAdmin',
	deleteOrganisation: '/organisation/deleteOrganisation',
	deleteLead: '/lead'
}

export default deleteUrl
