import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, DrawerBodyWrapper, H5, RadioButton, Textarea, TOAST_TYPE } from '@base'
import { Devices, Spacings, Text } from '@styles'
import { strings } from '@constants'
import { showLoader, showToast } from '@data/state/action'
import { useDispatch, useSelector } from 'react-redux'
import { toggleDrawer } from '@data/state/action/root'
import { User } from '@data/model'
import { addFinancialsRemark } from '@data'

const SectionItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;

	margin-top: ${Spacings.SPACING_2B};

	& > div {
		width: 100%;
	}
`
const FormWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-radius: ${Spacings.SPACING_2B};
`
const FormHeading = styled(H5)`
	margin-top: ${Spacings.SPACING_2B};
	margin-bottom: ${Spacings.SPACING_2B};
`
const RadioInputWapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	@media ${Devices.tablet} {
		flex-direction: row;
	}
	margin-bottom: ${Spacings.SPACING_2B};
`
const RadioButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
	padding: ${Spacings.SPACING_2B} 0;
`
const Label = styled.label`
	font-size: ${Text.MEDIUM};
	margin-left: ${Spacings.SPACING_2B};
	color: ${({ theme }) => theme.palette.text.black};
	font-weight: ${Text.BOLD_600};
	cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
	user-select: none;
`
const Footer = styled.div`
	padding: ${Spacings.SPACING_4B};
	box-shadow: 0 ${Spacings.SPACING_1B} ${Spacings.SPACING_5B} 0 rgb(0 0 0 / 5%);
	position: sticky;
	bottom: 0;
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;

	@media ${Devices.tablet} {
		justify-content: initial;
	}
`
const ActionButton = styled(Button)`
	margin-left: ${Spacings.SPACING_4B};
	max-width: 14rem;

	&:last-of-type {
		margin-left: 0;
	}

	flex-grow: 1;

	@media ${Devices.tablet} {
		flex-grow: 0;
	}

	margin: ${({ margin }) => margin};
`
const REMARK_TYPE = {
	CREDIT_COMMITTEE: 'CREDIT_COMMITTEE',
	CREDIT_APPROVER: 'CREDIT_APPROVER',
}
const remarkState = (userDetails) => {
	const _state = {
		remark: {
			id: 'remark',
			label: strings('remark'),
			placeholder: strings('enter', 'remark'),
			value: '',
			required: true,
		},
		remark_by: userDetails,
		created_at: new Date().getTime(),
	}

	return _state
}
const Remark = ({ organisation_id, update }) => {
    const dispatch = useDispatch()
	const user = useSelector((_state) => _state.getIn(['user', 'user']))
	const [formState, setFormState] = useState(remarkState(new User(user).getUserDetails()))
	const [selectRemarkType, setRemarkType] = useState(null)

	const onValueChange = (value) => {
		setRemarkType(value)
	}
	const updateState = (key) => (val) => {
		setFormState((_s) => ({
			..._s,
			[key]: {
				..._s[key],
				value: val,
			},
		}))
	}
	const onClickSave = () => {
		dispatch(showLoader(true))
		addFinancialsRemark(organisation_id, formState?.remark_by, selectRemarkType, formState?.remark?.value, formState?.created_at)
			.then(() => {
				update()
				dispatch(toggleDrawer(false))
				dispatch(showToast(true, strings('msg_financials_remark_added_success'), { type: TOAST_TYPE.SUCCESS }))
			})
			.catch((err) => console.log(err))
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const disableButton = !formState?.remark?.value || selectRemarkType === null

	return (
		<>
			<DrawerBodyWrapper>
				<FormWrapper>
					<SectionItemWrapper></SectionItemWrapper>
					<SectionItemWrapper>
						<RadioInputWapper>
							<RadioButtonWrapper>
								<RadioButton
									id='debit-radio'
									size='1.125'
									checked={selectRemarkType === REMARK_TYPE.CREDIT_COMMITTEE}
									value={REMARK_TYPE.CREDIT_COMMITTEE}
									onChange={(e) => onValueChange(REMARK_TYPE.CREDIT_COMMITTEE)}
								/>
								<Label htmlFor='debit-radio'>CREDIT COMMITTEE</Label>
							</RadioButtonWrapper>
							<RadioButtonWrapper>
								<RadioButton
									id='credit-radio'
									size='1.125'
									checked={selectRemarkType === REMARK_TYPE.CREDIT_APPROVER}
									value={REMARK_TYPE.CREDIT_APPROVER}
									onChange={(e) => onValueChange(REMARK_TYPE.CREDIT_APPROVER)}
								/>
								<Label htmlFor='credit-radio'>CREDIT APPROVER</Label>
							</RadioButtonWrapper>
						</RadioInputWapper>
					</SectionItemWrapper>
					<SectionItemWrapper>
						<Textarea
							id={formState.remark.id}
							type='text'
							rows={4}
							label={formState.remark.label}
							placeholder={formState.remark.placeholder}
							value={formState.remark.value}
							onChange={updateState('remark')}
						/>
					</SectionItemWrapper>
				</FormWrapper>
			</DrawerBodyWrapper>
			<Footer>
				<ActionButton small type='primary' disabled={disableButton} onClick={onClickSave}>
					{strings('save')}
				</ActionButton>
			</Footer>
		</>
	)
}

export default Remark
