import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Table, useTableReducer, PageLevelTableWrapper, Search, Button } from '@base'
import { commodityFilters, DRAWER_TYPES, strings, searchFilter } from '@constants'
import { displayAmount, showDateFormat } from '@utils'
import { toggleDrawer } from '@data/state/action/root'
import { CatalogNavigationWrapper, SearchWrapper } from './style'
import { getLatestCommodityPrices } from '@data/conn/get/commodity'
import { Spacings } from '@styles'
import { useForceUpdate } from '@hooks'

export const commodityProductName = { tmt: 'TMT', scrap: 'SCRAP', cr_coil: 'CRC', hr_coil: 'HRC', ingot: 'INGOT', pigiron: 'PIGIRON' }

export const commodityLocation = {
	ahmedabad: 'AHMEDABAD',
	alang: 'ALANG',
	bhiwadi: 'BHIWADI',
	chennai: 'CHENNAI',
	delhi: 'DELHI',
	durgapur: 'DURGAPUR',
	goa: 'GOA',
	hyderabad: 'HYDERABAD',
	indore: 'INDORE',
	jaipur: 'JAIPUR',
	jammu: 'JAMMU',
	jalna: 'JALNA',
	kandla: 'KANDLA',
	kanpur: 'KANPUR',
	kolkata: 'KOLKATA',
	ludhiana: 'LUDHIANA',
	mandigobindgarh: 'MANDIGOBINDGARH',
	mumbai: 'MUMBAI',
	muzzafarnagar: 'MUZZAFARNAGAR',
	raipur: 'RAIPUR',
}

export const commoditySource = {
	steelmint: 'STEELMINT',
	bigbits: 'BIGBITS',
	metalbook: 'METALBOOK',
}

const columns = [
	{
		label: strings('product', 'name'),
		key: (row) => {
			let data = row.primary_or_secondary ? `(${row.primary_or_secondary.substring(0,1).toUpperCase()})` : ''
			data += row.grade?` - ${row.grade}`: ''
			return row.product_name + data
		},
		mobile: true,
	},

	{
		label: strings('location'),
		key: 'location',
		mobile: true,
	},

	{
		label: strings('date'),
		key: 'price_date',
		mobile: true,
		renderer: (item) => {
			return <>{showDateFormat(new Date(item))}</>
		},
	},
	{
		label: strings(['price_per_ton']),
		align: 'center',
		key: 'price_per_ton',
		renderer: (item) => {
			return <>{displayAmount(item)}</>
		},
		mobile: true,
	},
]

const CommodityPrices = () => {
	const [state, dispatch, actions] = useTableReducer(commodityFilters)
	const globalDispatch = useDispatch()
	const [forceUpdate, update] = useForceUpdate()

	useEffect(() => {
		if (state.loading) return
		getProductList()
	}, [state.query, forceUpdate])

	const getProductList = () => {
		dispatch(actions.fetchTableDataProgress())
		getLatestCommodityPrices(state.query.page, { source: commoditySource.bigbits, ...state.query.filters })
			.then((response) => {
				dispatch(actions.fetchTableDataSuccess(response.data, response.count))
			})
			.catch((err) => dispatch(actions.fetchTableDataFailure(err)))
	}

	const clickHandler = () => {
		globalDispatch(toggleDrawer(true, DRAWER_TYPES.OPEN_SEARCH_PANEL, { searchType: searchFilter.COMMODITY_PRICE_SEARCH }))
	}

	const onClickHandler = () => {
		globalDispatch(toggleDrawer(true, DRAWER_TYPES.ADD_COMMODITY_PRICES, { label: strings(['add', 'commodity', 'price']), update: update }))
	}

	return (
		<>
			<CatalogNavigationWrapper>
				<SearchWrapper>
					<Button small type='primary' margin={`0 ${Spacings.SPACING_1B} 0 0`} onClick={onClickHandler}>
						{strings('add')}
					</Button>
					<Search clickHandler={clickHandler} />
				</SearchWrapper>
			</CatalogNavigationWrapper>
			<PageLevelTableWrapper>
				<Table fullHeight data={state.data} columns={columns} loading={state.loading} rowClickable={false} hideFooter />
			</PageLevelTableWrapper>
		</>
	)
}

export default CommodityPrices
