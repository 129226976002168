import React, { useMemo } from 'react'
import { DetailsWrapper, GridListWrapper } from '../../styles'
import { PageLevelTableWrapper, SectionHeader, Table } from '@base'
import { strings } from '@constants'
import { displayAmount } from '@utils'

const GetCollections = ({ financialData }) => {
	const collectionRemarks = financialData?.collection_remarks
	const columns = useMemo(
		() => [
			{
				label: 'Expected Date',
				key: (item) => {
					return item?.expected_payment_date ? item?.expected_payment_date : '-'
				},
				mobile: true,
			},
			{
				label: 'Expected Amount',
				key: (item) => {
					return item?.expected_amount ? displayAmount(item?.expected_amount) : '-'
				},
				mobile: true,
			},
			{
				label: 'Amount Received',
				key: (item) => {
					return item?.amount_received ? displayAmount(item?.amount_received) : '-'
				},
				mobile: true,
			},

			{
				label: 'Received %',
				key: (item) => {
					return item?.received_percentage ? displayAmount(item?.received_percentage) : '-'
				},
				mobile: true,
			},

			{
				label: 'Payment Type',
				key: (item) => {
					return item?.payment_type ? item?.payment_type : '-'
				},
				mobile: true,
			},
		],
		[]
	)

	return (
		<div>
			<DetailsWrapper>
				<SectionHeader>{strings('get', 'collections')}</SectionHeader>
				<GridListWrapper itemPerRow={[1, 1, 1]}>
					<PageLevelTableWrapper>
						<Table fullHeight data={collectionRemarks} columns={columns} page={0} hideFooter rowClickable={false} />
					</PageLevelTableWrapper>
				</GridListWrapper>
			</DetailsWrapper>
		</div>
	)
}

export default GetCollections
