import React from 'react'
import { DetailsWrapper, GridListWrapper } from '../../styles'
import { Button, ItemCard, SectionHeader } from '@base'
import { DRAWER_TYPES, strings } from '@constants'
import { toggleDrawer } from '@data/state/action/root'
import { useDispatch } from 'react-redux'
import { User } from '@data/model'

const BusinessDetails = ({ orgData, financialData, update }) => {
	let userDetails = new User({ ...orgData?.default_admin }).getUserDetails()
	const dispatch = useDispatch()
	const Edit =
		financialData?.business_types?.length ||
		financialData?.projects_key?.length ||
		financialData?.projects_completed?.length ||
		financialData?.projects_mbook?.length ||
		financialData?.departments?.length ||
		financialData?.materials?.length

	const buttonHandler = () => {
		Edit
			? editHandler()
			: dispatch(
					toggleDrawer(true, DRAWER_TYPES.BUSINESS_DETAILS, {
						label: 'Add Business Details',
						userDetails,
						organisationId: orgData?.organisation_id,
						update,
					})
			  )
	}

	const itemData = [
		{
			label: strings(['type', 'of', 'business']),
			value: financialData?.business_types ? financialData?.business_types.map((item) => item).join(', ') : '--',
		},
		{
			label: strings(['key', 'projects']),
			value: financialData?.projects_key ? financialData?.projects_key.map((item) => item).join(', ') : '--',
			wrap: true,
		},
		{
			label: strings(['completed', 'projects']),
			value: financialData?.projects_completed ? financialData?.projects_completed.map((item) => item).join(', ') : '--',
		},
		{
			label: strings(['mbook', 'projects']),
			value: financialData?.projects_mbook ? financialData?.projects_mbook.map((item) => item).join(', ') : '--',
		},

		{
			label: strings(['department', 'list']),
			value: financialData?.departments ? financialData?.departments.map((item) => item).join(', ') : '--',
		},
		{
			label: strings('material'),
			value: financialData?.materials ? financialData?.materials.map((item) => item).join(', ') : '--',
		},
	]

	const editHandler = () => {
		dispatch(
			toggleDrawer(true, DRAWER_TYPES.BUSINESS_DETAILS, {
				label: 'Edit Business Details',
				data: financialData,
				userDetails,
				organisationId: orgData?.organisation_id,
				update,
			})
		)
	}

	return (
		<>
			<DetailsWrapper>
				<SectionHeader>
					{strings('business', 'details')}
					{
						<Button xs onClick={buttonHandler}>
							{editHandler ? strings('edit') : strings('add')}
						</Button>
					}
				</SectionHeader>
				<GridListWrapper itemPerRow={[1, 1, 1]}>
					<ItemCard medium data={itemData} key={`business-details-item`} clickHandler={editHandler} clickable={financialData} />
				</GridListWrapper>
			</DetailsWrapper>
		</>
	)
}

export default BusinessDetails
