import React from 'react'
import { useDispatch } from 'react-redux'
import { Edit } from '@material-ui/icons'
import { SectionHeader } from '@base'
import { DRAWER_TYPES, strings } from '@constants'
import { ChipWapper, DetailsWrapper, EditIconWrapper, GridListWrapper, Label, LabelWrapper, MenuIcon, TextValue } from '../../styles'
import { toggleDrawer } from '@data/state/action/root'

const CustomerDetails = ({ orgData, financialData, update }) => {
	const dispatch = useDispatch()

	const gstDetails = orgData?.gst_details?.map((item) => item?.gst) || []

	const _data = gstDetails?.length < 3 ? gstDetails : gstDetails?.slice(0, 2)
	const getGst = _data?.filter(Boolean).join(', ')
	const labelMsg = gstDetails?.length >= 0 && `+${gstDetails?.length - _data.length} more`

	const obj = () => {
		return (
			<>
				{getGst} {gstDetails?.length - _data.length > 0 && <ChipWapper label={labelMsg} uppercase={false} status={'custom'} />}
			</>
		)
	}

	const bankHandler = () => {
		dispatch(
			toggleDrawer(true, DRAWER_TYPES.FINANCIAL_ORG_BANK, {
				label: 'Edit Bank Details',
				update,
				data: orgData,
				financialData: financialData,
			})
		)
	}
	const paymentModeHandler = () => {
		dispatch(
			toggleDrawer(true, DRAWER_TYPES.FINANCIAL_ORG_PAYMENT_MODE, {
				label: 'Edit Payment Mode',
				update,
				data: orgData,
				financialData: financialData,
			})
		)
	}
	const securityHandler = () => {
		dispatch(
			toggleDrawer(true, DRAWER_TYPES.FINANCIAL_ORG_SECURITY, {
				label: 'Edit Security',
				update,
				data: orgData,
				financialData: financialData,
			})
		)
	}

	return (
		<>
			<DetailsWrapper>
				<SectionHeader>{strings('organisation', 'details')}</SectionHeader>
				<GridListWrapper itemPerRow={[3, 3, 3]}>
					<div>
						<LabelWrapper>
							<Label>{strings('name')}</Label>
						</LabelWrapper>
						<TextValue>{orgData?.client_details ? orgData?.client_details?.organisation_name : '--'}</TextValue>
					</div>
					<div>
						<LabelWrapper>
							<Label>{strings('region')}</Label>
						</LabelWrapper>
						<TextValue>{orgData?.region ? orgData?.region : '--'}</TextValue>
					</div>
					<div>
						<LabelWrapper>
							<Label>{strings('gst')}</Label>
						</LabelWrapper>
						<TextValue>{obj() || '--'}</TextValue>
					</div>
					<div>
						<LabelWrapper>
							<Label>Platform Department</Label>
						</LabelWrapper>
						<TextValue>{financialData?.platform ? financialData?.platform : '--'}</TextValue>
					</div>
					<div>
						<LabelWrapper>
							<Label>
								Bank
								<EditIconWrapper type='secondary' small uppercase onClick={() => bankHandler()}>
									<MenuIcon>
										<Edit fontSize='small' />
									</MenuIcon>
								</EditIconWrapper>
							</Label>
						</LabelWrapper>
						<TextValue>{financialData?.mbook_bank ? financialData?.mbook_bank?.bank_name : '--'}</TextValue>
					</div>
					<div>
						<LabelWrapper>
							<Label>
								Payment Mode
								<EditIconWrapper type='secondary' small uppercase onClick={() => paymentModeHandler()}>
									<MenuIcon>
										<Edit fontSize='small' />
									</MenuIcon>
								</EditIconWrapper>
							</Label>
						</LabelWrapper>
						<TextValue>{financialData?.payment_mode ? financialData?.payment_mode : '--'}</TextValue>
					</div>
					<div>
						<LabelWrapper>
							<Label>
								Security
								<EditIconWrapper type='secondary' small uppercase onClick={() => securityHandler()}>
									<MenuIcon>
										<Edit fontSize='small' />
									</MenuIcon>
								</EditIconWrapper>
							</Label>
						</LabelWrapper>
						<TextValue>{financialData?.security ? financialData?.security : '--'}</TextValue>
					</div>
					<div>
						<LabelWrapper>
							<Label>Sales person</Label>
						</LabelWrapper>
						<TextValue>{financialData?.sales_admin ? financialData?.sales_admin?.full_name : '--'}</TextValue>
					</div>
				</GridListWrapper>
			</DetailsWrapper>
		</>
	)
}

export default CustomerDetails
