import React, { memo, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Calendar, DrawerBodyWrapper, Input, Select, TOAST_TYPE, UploadMultipleFileButton } from '@base'
import { ActionButton, CustomSectionHeader, FormItemWrapper, FormWrapper } from './styles'
import { strings } from '@constants'
import { User } from '@data/model'
import { Spacings } from '@styles'
import { addOtherInvoiceDocuments, updateInvoices } from '@data'
import { showLoader, showToast } from '@data/state/action'
import { toggleFileViewer, updateDrawerMeta } from '@data/state/action/root'

const deriveStateFromData = (data, _users) => {
	const _state = {
		invoice_amount: {
			label: strings('invoice', 'amount'),
			value: '',
			disabled: false,
			required: true,
			placeholder: strings(['enter', 'invoice', 'amount']),
		},
		original_invoice_number: {
			label: strings('invoice', 'number'),
			value: '',
			disabled: false,
			required: true,
			placeholder: strings(['enter', 'invoice', 'number']),
		},
		invoice_date: {
			label: strings('invoice', 'date'),
			value: '',
			disabled: false,
			placeholder: strings(['enter', 'invoice', 'date']),
		},
		service_type: {
			label: strings('service', 'type'),
			value: { status: '-' },
			disabled: false,
			placeholder: strings(['enter', 'service', 'type']),
			data: [{ status: 'Logistics' }, { status: 'Lead' }, { status: 'TCS' }, { status: 'TDS' }],
			displayKey: 'status',
			required: true,
		},
		userDetails: {
			label: strings('select_user'),
			value: {},
			disabled: false,
			placeholder: strings('select_user'),
			data: _users,
			displayKey: 'display_name',
			primaryKey: 'organisation_id',
			required: true,
		},
	}

	if (!data) return _state
	_state.invoice_amount.value = data?.invoice_amount
	_state.original_invoice_number.value = data?.original_invoice_number
	_state.invoice_date.value = data?.invoice_date
	_state.service_type.value = { status: data?.service_type || '-' }
	_state.userDetails.value = _users.find((item) => item?.user_id === data?.user_details?.user_id) || {}

	return _state
}

const Index = memo(({ _users, update }) => {
	const invoiceData = useSelector((state) => state.getIn(['root', 'drawerTypeMeta', 'invoiceData']))
	const dispatch = useDispatch()
	const [formState, setFormState] = useState(deriveStateFromData())

	useEffect(() => {
		setFormState((_) => deriveStateFromData(invoiceData, _users))
	}, [invoiceData])

	useEffect(() => {
		dispatch(
			updateDrawerMeta({
				label: (
					<>
						{strings('bill', 'details')}
						<ActionButton
							small
							uppercase
							type='secondary'
							isHover
							onClick={() => {
								dispatch(toggleFileViewer(true, { files: [invoiceData?.invoice_document] }))
							}}
						>
							{strings('view', 'bill')}
						</ActionButton>
					</>
				),
			})
		)
	}, [dispatch, invoiceData])

	const onChange = (key) => (value) => updateState(key, { value })

	const updateState = (key, updates) =>
		setFormState((state) => ({
			...state,
			[key]: {
				...state[key],
				...updates,
			},
		}))

	const updateInvoiceInfo = () => {
		if (disabledUpdateButton) return
		const user_details = new User(formState.userDetails.value).getUserDetails()

		dispatch(showLoader(true))
		const _updateFormData = {
			...invoiceData,
			user_details,
			invoice_date: formState.invoice_date.value ?? new Date().getTime(),
			invoice_amount: formState.invoice_amount.value,
			original_invoice_number: formState.original_invoice_number.value,
			service_type: formState.service_type.value?.status === '-' ? null : formState.service_type.value?.status || null,
		}
		updateInvoices(_updateFormData)
			.then((response) => {
				dispatch(showToast(true, strings('msg_invoice_details_updated'), { type: TOAST_TYPE.SUCCESS }))
				dispatch(
					updateDrawerMeta({
						invoiceData: {
							...response?.data,
						},
					})
				)
				update()
			})
			.catch((err) => {
				dispatch(showToast(true, strings('msg_failed_to_update_invoice_details'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const uploadOtherDocuments = (files) => {
		if (files.length < 1) return

		const getPath = Array.from(files).map((f) => f)
		dispatch(showLoader(true))
		const filed = getPath.map((item, index) => {
			return addOtherInvoiceDocuments(item, invoiceData.invoice_number)
				.then((response) => {
					if (response?.other_documents.length > 0) {
						return response?.other_documents
					} else {
						return Promise.reject()
					}
				})
				.catch((err) => {
					dispatch(showToast(true, `${strings('document_upload_failed')} ${err}`, { type: TOAST_TYPE.ERROR }))
				})
		})

		Promise.all(filed).then((values) => {
			dispatch(showLoader(false))
			if (values.includes(undefined)) {
				dispatch(showToast(true, strings('document_upload_failed'), { type: TOAST_TYPE.ERROR }))
			} else {
				invoiceData.other_documents = values[0]
				dispatch(
					updateDrawerMeta({
						invoiceData: {
							...invoiceData,
						},
					})
				)
				dispatch(showToast(true, strings('invoice_uploaded_successfully'), { type: TOAST_TYPE.SUCCESS }))
				update()
			}
		})
	}

	const disabledUpdateButton = useMemo(() => {
		if (!invoiceData) return true
		const nonDisabledEntries = Object.entries(formState).filter(([key, val]) => !val.disabled)

		return (
			nonDisabledEntries.some(([key, val]) => val.required && !val.value) ||
			nonDisabledEntries.every(([key, val]) => val.value === invoiceData[key])
		)
	}, [formState, invoiceData])

	const viewOtherHandler = () => {
		dispatch(toggleFileViewer(true, { files: invoiceData?.other_documents }))
	}

	return (
		<DrawerBodyWrapper>
			<FormWrapper>
				<FormItemWrapper>
					<Select
						primaryKey={formState.userDetails?.primaryKey}
						displayKey={formState.userDetails?.displayKey}
						data={formState.userDetails?.data}
						label={formState.userDetails?.label}
						disabled={formState.userDetails?.disabled}
						value={formState.userDetails?.value}
						placeholder={formState.userDetails?.placeholder}
						onChange={onChange('userDetails')}
						required={formState.userDetails?.required}
					/>
				</FormItemWrapper>
				<FormItemWrapper>
					<Input
						type='text'
						label={formState.original_invoice_number?.label}
						disabled={formState.original_invoice_number?.disabled}
						value={formState.original_invoice_number?.value}
						placeholder={formState.original_invoice_number?.placeholder}
						onChange={onChange('original_invoice_number')}
						required={formState.original_invoice_number?.required}
					/>
				</FormItemWrapper>
				<FormItemWrapper>
					<Calendar
						label={formState.invoice_date?.label}
						disabled={formState.invoice_date?.disabled}
						value={formState.invoice_date?.value}
						placeholder={formState.invoice_date?.placeholder}
						onChange={onChange('invoice_date')}
						required={formState.invoice_date?.required}
					/>
				</FormItemWrapper>
				<FormItemWrapper>
					<Input
						type='text'
						label={formState.invoice_amount?.label}
						disabled={formState.invoice_amount?.disabled}
						value={formState.invoice_amount?.value}
						placeholder={formState.invoice_amount?.placeholder}
						onChange={onChange('invoice_amount')}
						required={formState.invoice_amount?.required}
					/>
				</FormItemWrapper>
				<FormItemWrapper>
					<Select
						primaryKey={formState.service_type?.displayKey}
						displayKey={formState.service_type?.displayKey}
						data={formState.service_type?.data}
						label={formState.service_type?.label}
						disabled={formState.service_type?.disabled}
						value={formState.service_type?.value}
						placeholder={formState.service_type?.placeholder}
						onChange={onChange('service_type')}
						required={formState.service_type?.required}
					/>
				</FormItemWrapper>

				<FormItemWrapper>
					<ActionButton type='primary' small uppercase disabled={disabledUpdateButton} onClick={updateInvoiceInfo}>
						{strings('update')}
					</ActionButton>
				</FormItemWrapper>
			</FormWrapper>
			<>
				<CustomSectionHeader>{strings('other', 'documents')}</CustomSectionHeader>
				<FormItemWrapper isStock>
					<ActionButton noAutoLeft small uppercase type='secondary' onClick={viewOtherHandler} disabled={!invoiceData?.other_documents}>
						{strings(['view', 'other', 'documents'])}
					</ActionButton>
					<UploadMultipleFileButton
						label={strings(['upload', 'other', 'documents'])}
						small
						uppercase
						margin={`0 0 0 ${Spacings.SPACING_2B}`}
						acceptFile={'image/*'}
						onChange={uploadOtherDocuments}
					/>
				</FormItemWrapper>
			</>
		</DrawerBodyWrapper>
	)
})

export default Index
