import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { withTheme } from 'styled-components'

import { Badge, BadgeLabel, BadgeWrapper, InfoWrapper, ProfileActionButton, UserBadgeSection } from './styles'
import { Button, Input, Loader, Select, TOAST_TYPE } from '@base'
import { roles, fireBaseError } from '@constants'
import { getUserByID, editUser } from '@data'
import { showToast } from '@data/state/action'
import strings from '@constants/strings'
import { RecaptchaContainer } from '@pages/Login/styles'
import { toggleDrawer } from '@data/state/action/root'
import { Spacings } from '@styles'
import { isClient } from 'src/utils/adminFilter'

const deriveFormStateFromProps = (defaultValue) => ({
	role: {
		default: defaultValue?.role,
		value: defaultValue?.role ?? '',
		valid: true,
	},
	full_name: {
		default: defaultValue?.full_name,
		value: defaultValue?.full_name ?? '',
		valid: false,
	},
	email_id: {
		default: defaultValue?.email_id ?? '',
		value: defaultValue?.email_id ?? '',
		valid: true,
	},
	mobile_number: {
		default: defaultValue?.mobile_number,
		value: defaultValue?.mobile_number ?? '',
		valid: false,
	},
	otp: {
		value: '',
		default: '',
		valid: true,
	},
})

const Roles = Object.values(roles)

const PersonalDetailsComponent = ({ update, editable, updateUserProfile, requestSupplierAccessClick, theme }) => {
	const dispatch = useDispatch()
	const userId = useParams()?.userId
	const [userData, setUserData] = useState({})
	const [userRoles, setUserRoles] = useState(Roles.map((item) => ({ value: item })))

	const [isOtpSent, setIsOTPSent] = useState(false)
	const [loading, setLoading] = useState(false)

	const user = useSelector((state) => state.getIn(['user', 'user']))
	const userUpdateInProgress = useSelector((state) => state.getIn(['user', 'userUpdateInProgress']))
	const [formState, setFormState] = useState({})

	const appVerifier = useRef()
	const confirmationResult = useRef()

	useEffect(() => {
		if (userId) {
			getUserByID(userId).then((response) => {
				setUserData(response)
			})
		} else {
			setUserData(user)
		}
	}, [userId, user, userUpdateInProgress])

	useEffect(() => {
		let _userRole = ''
		if (userData?.supplier_rights_requested) {
			_userRole = userRoles.find((item) => item.value === roles.trader)
		}
		if (userData?.logistics_rights_requested) {
			_userRole = userRoles.find((item) => item.value === roles.logistic)
		}
		if (userData?.fabricator_rights_requested) {
			_userRole = userRoles.find((item) => item.value === roles.fabricator)
		}
		_userRole && setUserRoles([userRoles[0], _userRole])
		setFormState(deriveFormStateFromProps(userData))
	}, [userData])

	useEffect(() => {
		if (update) {
			const values = Object.values(formState)
			const disableSaveButton = !!values.find((i) => !i.valid) || values.every((i) => i.default === i.value)
			const requestByAdmin =
				formState?.role?.value !== userData.role
					? { logistics_rights_requested: false, supplier_rights_requested: false, fabricator_rights_requested: false }
					: {}
			update({
				data: {
					full_name: formState.full_name?.value.trim(),
					email_id: formState.email_id?.value.trim(),
					mobile_number: formState.mobile_number?.value.trim(),
					role: formState.role?.value.trim(),
					...requestByAdmin,
				},
				disableSaveButton,
			})
		}
	}, [formState, update, userData])

	const updateState = (inputType, key) => (value) => {
		if (inputType === 'role') {
			value = value.value
		}

		setFormState((state) => ({
			...state,
			[inputType]: {
				...state[inputType],
				[key]: value,
			},
		}))
	}

	const addEmailHandler = () => {
		if (!formState.email_id.value) {
			dispatch(showToast(true, strings('msg_enter_email_password'), { type: TOAST_TYPE.ERROR }))
			return
		}

		setLoading(true)
		const firebaseUser = firebase.auth().currentUser

		firebaseUser
			.updateEmail(formState.email_id.value)
			.then((_response) => {
				emailAddedHandler(firebaseUser)
			})
			.catch((err) => {
				if (err.code === fireBaseError.recent_login_reqd) {
					if (!appVerifier.current) {
						appVerifier.current = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
							size: 'invisible',
							callback: () => {},
						})
					}

					firebaseUser
						.reauthenticateWithPhoneNumber(firebaseUser.phoneNumber, appVerifier.current)
						.then((_confirmationResult) => {
							confirmationResult.current = _confirmationResult
							setIsOTPSent(true)
						})
						.catch(() => {
							setIsOTPSent(false)
						})
				} else if (err.code === fireBaseError.email_exists || err.code === fireBaseError.email_already_use) {
					dispatch(showToast(true, strings('error_email_exists'), { type: TOAST_TYPE.ERROR }))
				}
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const submitOTPHandler = () => {
		const firebaseUser = firebase.auth().currentUser
		if (confirmationResult.current) {
			setLoading(true)
			confirmationResult.current
				.confirm(formState.otp.value)
				.then(() => {
					firebaseUser
						.updateEmail(formState.email_id.value)
						.then(() => {
							emailAddedHandler(firebaseUser)
						})
						.catch((_err) => {
							if (_err.code === fireBaseError.email_exists || _err.code === fireBaseError.email_already_use) {
								dispatch(showToast(true, strings('error_email_exists'), { type: TOAST_TYPE.ERROR }))
							}
						})
				})
				.catch((error) => {
					if (error.code === fireBaseError.invalidVerificationCode) {
						dispatch(showToast(true, strings('msg_invalid_verification_code'), { type: TOAST_TYPE.ERROR }))
					}
					if (error.code === fireBaseError.maximumUserCountExceeded) {
						dispatch(showToast(true, strings('msg_maximum_user_count_exceeded'), { type: TOAST_TYPE.ERROR }))
					}
				})
				.finally(() => {
					setLoading(false)
				})
		}
	}

	const emailAddedHandler = (firebaseUser) => {
		updateEmailHandler(firebaseUser)
	}

	const updateEmailHandler = (firebaseUser) => {
		setLoading(true)
		firebaseUser.sendEmailVerification().catch((_err) => {
			dispatch(showToast(true, strings('msg_verify_email'), { type: TOAST_TYPE.INFO }))
		})

		userData.update({ email_id: formState.email_id.value })
		editUser(userData)
			.then((_res) => {
				dispatch(showToast(true, strings('msg_email_added_successfully'), { type: TOAST_TYPE.SUCCESS }))
				dispatch(toggleDrawer(false))
				updateUserProfile(formState?.data, false)
			})
			.catch((_err) => {})
			.finally(() => {
				setLoading(false)
			})
	}

	const editableMobileNumber = !isClient(user.role) && editable ? false : true

	return (
		<>
			{!editable && (
				<UserBadgeSection fullWidth>
					<BadgeWrapper>
						<BadgeLabel>{strings('role')}</BadgeLabel>
						<Badge>{userData?.role}</Badge>
					</BadgeWrapper>
				</UserBadgeSection>
			)}
			<InfoWrapper>
				<Input
					label={strings('name')}
					id='profile-name'
					type='text'
					disabled={!editable}
					value={formState?.full_name?.value}
					onChange={updateState('full_name', 'value')}
					setValidity={updateState('full_name', 'valid')}
					placeholder={strings('your_full_name')}
					errorMap={{
						valueMissing: `${strings('name_required')}`,
					}}
					required
				/>
			</InfoWrapper>
			<InfoWrapper>
				<Input
					label={strings('mobile')}
					id='profile-mobile'
					type='tel'
					pattern='^\d{7}\d+$'
					maxLength={20}
					errorMap={{
						patternMismatch: `${strings('enter_valid_num')}`,
						valueMissing: `${strings('mob_num_required')}`,
					}}
					disabled={editableMobileNumber}
					value={formState?.mobile_number?.value}
					onChange={updateState('mobile_number', 'value')}
					setValidity={updateState('mobile_number', 'valid')}
					placeholder={strings('enter_your_mobile_num')}
					required
				/>
			</InfoWrapper>
			{userId && editable && (
				<InfoWrapper>
					<Select
						id='role-select'
						label={strings('role')}
						displayKey='value'
						primaryKey='value'
						placeholder={strings('select_role')}
						data={userRoles}
						value={formState?.role}
						onChange={updateState('role', 'value')}
					/>
				</InfoWrapper>
			)}
			<InfoWrapper>
				<Input
					label={strings('email')}
					id='profile-email'
					type='email'
					pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
					value={formState?.email_id?.value}
					onChange={updateState('email_id', 'value')}
					setValidity={updateState('email_id', 'valid')}
					errorMap={{
						patternMismatch: `${strings('enter_valid_email')}`,
					}}
					placeholder={strings('your_email_address')}
					disabled={!editable || isOtpSent}
				/>
			</InfoWrapper>
			{!editable && (
				<InfoWrapper>
					<Input
						label={strings(['reporting', 'manager'])}
						id='reporting-manager-name'
						type='text'
						disabled={true}
						value={user?.manager_name}
					/>
				</InfoWrapper>
			)}
			{editable && (
				<>
					<RecaptchaContainer id='recaptcha-container'></RecaptchaContainer>
					<Button
						small
						uppercase
						onClick={addEmailHandler}
						disabled={loading || isOtpSent || !formState.email_id?.value || !formState.email_id?.valid}
						margin={`${Spacings.SPACING_3B} 0 0 0`}
					>
						{loading ? <Loader size={2} color={theme.palette.background.white} /> : strings('setup', 'email')}
					</Button>
				</>
			)}
			{isOtpSent && (
				<>
					<InfoWrapper>
						<Input
							id='reauth-otp'
							type='number'
							placeholder={strings('digits_otp')}
							value={formState?.otp?.value}
							onChange={updateState('otp', 'value')}
							setValidity={updateState('otp', 'valid')}
							pattern='\d{6}$'
							maxLength={6}
							errorMap={{
								patternMismatch: `${strings('enter_digit_otp')}`,
							}}
							disabled={!isOtpSent}
						/>
					</InfoWrapper>
					<ProfileActionButton
						small
						uppercase
						onClick={submitOTPHandler}
						disabled={loading || !(formState.email_id?.value && formState.otp?.value)}
					>
						{loading ? <Loader size={2} color={theme.palette.background.white} /> : strings('submit', 'otp')}
					</ProfileActionButton>
				</>
			)}
		</>
	)
}

export default withTheme(PersonalDetailsComponent)
