import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { strings } from '@constants'
import { getBankDetails } from '@data'
import { Button, DrawerBodyWrapper, Select } from '@base'
import { FormItemWrapper, InputWrapper } from '../Pdc/PdcForm'

const ItemWrapper = styled(InputWrapper)`
	&:nth-child(2) {
		flex: 1;
	}
`

const getFormState = (data) => {
	const _state = {
		bank: {
			id: 'bank',
			label: strings('select', 'bank'),
			placeholder: strings(['select', 'bank']),
			disabled: false,
			value: {},
			required: true,
		},
	}
	if (data) {
		_state.bank.value = { institution_id: data?.bank, name: data?.bank }
	}
	return _state
}
const BankForm = ({ data, update }) => {
	const [bankList, setBankList] = useState([])
	const [formState, setFormState] = useState(getFormState())

	useEffect(() => {
		getBankDetails()
			.then((response) => {
				setBankList(response)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	useEffect(() => {
		setFormState(getFormState(data))
	}, [data])

	const updateState = (key) => (value) => {
		setFormState((_s) => {
			return {
				..._s,
				[key]: {
					..._s[key],
					value,
				},
			}
		})
	}

	const buttonHandler = () => {
		const { bank } = formState
		console.log(bank)
	}

	return (
		<DrawerBodyWrapper>
			<FormItemWrapper>
				<InputWrapper>
					<Select
						id={formState.bank.id}
						label={formState.bank?.label}
						placeholder={formState.bank?.placeholder}
						data={bankList}
						displayKey='name'
						primaryKey='institution_id'
						value={formState.bank.value ?? {}}
						onChange={updateState('bank')}
						disabled={formState.bank.disabled}
						required={formState.bank.required}
						errorMap={{
							valueMissing: `${strings('bank is required')}`,
						}}
					/>
				</InputWrapper>
				<ItemWrapper>
					<Button disabled={false} onClick={() => buttonHandler()}>
						Update
					</Button>
				</ItemWrapper>
			</FormItemWrapper>
		</DrawerBodyWrapper>
	)
}

export default BankForm
