import { PageLevelTableWrapper, Table, TableActionButton, TableActionButtonWrapper, TableColumns, useTableReducer } from '@base'
import { DRAWER_TYPES, LeadFilter, strings } from '@constants'
import { getLeadsByOrgId } from '@data/conn/get/lead'
import { toggleDrawer } from '@data/state/action/root'
import { useForceUpdate } from '@hooks'
import { paths } from '@routes'
import { getDate } from '@utils'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'

const OrgLeadList = () => {
	const [forceUpdate, update] = useForceUpdate()
	const globalDispatch = useDispatch()
	const navigate = useNavigate()
	const [state, dispatch, actions] = useTableReducer(LeadFilter)
	const { orgId } = useParams()
	useEffect(() => {
		if (state.loading) {
			return
		}
		dispatch(actions.fetchTableDataProgress())

		getLeadsByOrgId(orgId, state.query.page, null, state.query.filters)
			.then((response) => {
				dispatch(actions.fetchTableDataSuccess(response.data, response.count))
			})
			.catch((err) => {
				dispatch(actions.fetchTableDataFailure(err))
			})
	}, [state.query])

	const changePageHandler = (next = true) => {
		dispatch(actions.changePage(next))
	}

	const onRowClick = (row) => {
		// if (row?.user_details?.organisation_id && row?.lead_id) {
		// 	navigate(paths.admin_organisation_lead_detail(row?.user_details?.organisation_id, row?.lead_id))
		// }
		if (row?.lead_id) {
			navigate(paths.admin_lead_detail(row?.lead_id))
		}
	}

	const leadJourneyHandler = (row) => (e) => {
		e.stopPropagation()
		globalDispatch(toggleDrawer(true, DRAWER_TYPES.LEAD_JOURNEY, { label: strings('lead', 'journey'), leadId: row?.lead_id }))
	}

	const columns = useMemo(
		() => [
			{
				label: strings('created', 'at'),
				key: 'created_at',
				renderer: (data) => <div>{getDate(new Date(data))}</div>,
			},
			TableColumns.actionsColumn({
				label: strings('organisation', 'id'),
				key: 'user_details',
				renderer: (data) => (
					<>
						<div>
							<b>{data?.organisation_id}</b>
						</div>
						<div>
							<small>{data?.mobile_number}</small>
						</div>
					</>
				),
				mobile: false,
			}),
			{
				label: strings(['organisation', 'name', '/', 'location', 'pincode']),
				key: (data) => data,
				renderer: (data) => (
					<>
						<div>
							<b>{data?.user_details?.organisation_name}</b>
						</div>
						<div>
							<span>{data?.location}</span>
						</div>
					</>
				),
				mobile: true,
			},

			{
				label: strings('business', 'category'),
				key: 'category',
				renderer: (data) => <div>{data}</div>,
				mobile: true,
			},
			{
				label: strings('sales', 'manager'),
				key: (data) => data,
				renderer: (data) => {
					if (!data?.manager) return '-'
					return (
						<>
							<div>
								<b>{data?.manager?.find((_item) => _item?.is_primary)?.full_name ?? '-'}</b>
							</div>
							<div>
								<small>{data?.manager?.find((_item) => _item?.is_primary)?.mobile_number ?? '-'}</small>
							</div>
						</>
					)
				},
			},

			{
				label: strings(['status', '/', 'meeting']),
				key: (data) => data,
				mobile: true,
				renderer: (data) => {
					if (!data?.status) return '-'
					return (
						<>
							<div>
								<b>{data?.status}</b>
							</div>
							{data?.meeting_task?.date && data?.meeting_task?.date != 0 ? (
								<div>
									<small>{getDate(new Date(data?.meeting_task?.date))}</small>
								</div>
							) : data?.follow_up_task?.date && data?.follow_up_task?.date != 0 ? (
								<div>
									<small>{getDate(new Date(data?.follow_up_task?.date))}</small>
								</div>
							) : (
								<div>{'-'}</div>
							)}
						</>
					)
				},
			},
			{
				label: strings('source'),
				key: 'source',
			},
			{
				label: strings('total_requirement'),
				key: (data) => data,
				renderer: (data) => (
					<>
						<div>{data?.weight ?? '-'}</div>
					</>
				),
				mobile: true,
				align: 'center',
			},
		],
		[state.query]
	)
	return (
		<PageLevelTableWrapper>
			<Table
				fullHeight
				data={state.data}
				columns={columns}
				loading={state.loading}
				rowsToDisplay={10}
				totalItemCount={state.total_count}
				page={state.query.page}
				onPageChange={changePageHandler}
				onRowClick={onRowClick}
			/>
		</PageLevelTableWrapper>
	)
}

export default OrgLeadList
