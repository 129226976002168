import { Button, DrawerBodyWrapper, DrawerFooterWrapper, Input, Select, TOAST_TYPE } from '@base'
import { FieldWrapper, BiFieldWrapper } from '../styles'
import { CountryCodes, ORG_USER_ROLES, strings } from '@constants'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showLoader, showToast } from '@data/state/action'
import { addUser } from '@data/conn/post/organisation'
import { toggleDrawer } from '@data/state/action/root'
import { createUser } from '@data'

const defaultState = {
	role: {
		value: ORG_USER_ROLES.ORG_ADMIN,
		valid: true,
	},
	full_name: {
		value: '',
		valid: true,
		required: true,
	},
	email_id: {
		value: '',
		valid: true,
	},
	mobile_number: {
		value: '',
		valid: true,
		required: true,
	},
}

const ROLES = Object.entries(ORG_USER_ROLES).map(([key, value]) => ({
	value,
	key,
}))

const AddNewUser = ({ orgId, update, newAdmin }) => {
	const dispatch = useDispatch()
	const [details, setDetails] = useState(defaultState)
	const [countryCode, setCountryCode] = useState(CountryCodes[0])

	const user = useSelector((_state) => _state.getIn(['user', 'user']))

	const updateState = (field, key) => (value) => {
		if (field === 'role') {
			value = value.value
		}
		setDetails((state) => ({
			...state,
			[field]: {
				...state[field],
				[key]: value,
			},
		}))
	}

	const disabled = useMemo(() => {
		return Object.values(details).some((d) => !d.valid || (d.required && !d.value))
	}, [details])

	const addUserHandler = () => {
		dispatch(showLoader(true))
		addUser({
			organisationId: orgId,
			userId: user?.user_id,
			email: details.email_id.value,
			mobile: countryCode.dial_code.replace('+', '') + details.mobile_number.value,
			name: details.full_name.value,
			role: details.role.value,
		})
			.then(() => {
				dispatch(showToast(true, 'Successfully added user', { type: TOAST_TYPE.SUCCESS }))
				update()
				dispatch(toggleDrawer(false))
			})
			.catch((err) => {
				console.log(err)
				dispatch(showToast(true, 'Error while adding user', { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const addAdminHandler = () => {
		dispatch(showLoader(true))
		createUser({
			userId: user?.user_id,
			email: details.email_id.value,
			mobile: countryCode.dial_code.replace('+', '') + details.mobile_number.value,
			name: details.full_name.value,
			role: 'ADMIN',
		})
			.then(() => {
				dispatch(showToast(true, 'Successfully created new admin user', { type: TOAST_TYPE.SUCCESS }))
				update()
				dispatch(toggleDrawer(false))
			})
			.catch((err) => {
				console.log(err)
				dispatch(showToast(true, strings('user_already_exist'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	return (
		<>
			<DrawerBodyWrapper>
				<FieldWrapper>
					<Input
						label={strings('name')}
						id='user-name'
						type='text'
						value={details?.full_name?.value}
						onChange={updateState('full_name', 'value')}
						setValidity={updateState('full_name', 'valid')}
						placeholder={strings('your_full_name')}
						errorMap={{
							valueMissing: `${strings('name_required')}`,
						}}
					/>
				</FieldWrapper>
				<BiFieldWrapper>
					<Select
						id='login-country-code'
						data={CountryCodes}
						displayKey='dial_code'
						primaryKey='name'
						value={countryCode}
						onChange={setCountryCode}
						disabled={false}
						type='tel'
						noDropIcon
					/>
					<Input
						label={strings('mobile')}
						id='user-mobile'
						type='tel'
						pattern='^\d{7}\d+$'
						maxLength={20}
						errorMap={{
							patternMismatch: `${strings('enter_valid_num')}`,
							valueMissing: `${strings('mob_num_required')}`,
						}}
						value={details?.mobile_number?.value}
						onChange={updateState('mobile_number', 'value')}
						setValidity={updateState('mobile_number', 'valid')}
						placeholder={strings('enter_your_mobile_num')}
					/>
				</BiFieldWrapper>
				<FieldWrapper>
					<Input
						label={strings('email')}
						id='user-email'
						type='email'
						pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
						value={details?.email_id?.value}
						onChange={updateState('email_id', 'value')}
						setValidity={updateState('email_id', 'valid')}
						errorMap={{
							patternMismatch: `${strings('enter_valid_email')}`,
						}}
						placeholder={strings('your_email_address')}
					/>
				</FieldWrapper>
				{!newAdmin && (
					<FieldWrapper>
						<Select
							id='role-select'
							label={strings('role')}
							displayKey='value'
							primaryKey='key'
							placeholder={strings('select_role')}
							data={ROLES}
							value={details?.role}
							onChange={updateState('role', 'value')}
						/>
					</FieldWrapper>
				)}
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small disabled={disabled} onClick={newAdmin ? addAdminHandler : addUserHandler}>
					Create {newAdmin ? 'Admin' : 'User'}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default AddNewUser
