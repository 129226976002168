import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { strings } from '@constants'
import { Button, DrawerBodyWrapper, Select } from '@base'
import { FormItemWrapper, InputWrapper } from '../Pdc/PdcForm'

const ItemWrapper = styled(InputWrapper)`
	&:nth-child(2) {
		flex: 1;
	}
`

const _modeType = [{ name: 'CASH' }]

const getFormState = (data) => {
	const _state = {
		payment_mode: {
			id: 'mode-type',
			label: strings(['select', 'mode', 'type']),
			placeholder: strings(['select', 'mode', 'type']),
			disabled: false,
			value: {},
			required: true,
		},
	}
	if (data) {
		_state.payment_mode.value = _modeType?.find((item) => item?.name === data?.payment_mode)
	}
	return _state
}
const PaymentModeForm = ({ data, update, financialData }) => {
	const [formState, setFormState] = useState(getFormState())

	useEffect(() => {
		setFormState(getFormState(financialData))
	}, [financialData])

	const updateState = (key) => (value) => {
		setFormState((_s) => {
			return {
				..._s,
				[key]: {
					..._s[key],
					value,
				},
			}
		})
	}

	const buttonHandler = () => {
		const { payment_mode } = formState
		console.log(payment_mode)
	}

	return (
		<DrawerBodyWrapper>
			<FormItemWrapper>
				<InputWrapper>
					<Select
						id={formState.payment_mode.id}
						label={formState.payment_mode?.label}
						placeholder={formState.payment_mode?.placeholder}
						data={_modeType}
						displayKey='name'
						primaryKey='name'
						value={formState.payment_mode.value ?? {}}
						onChange={updateState('payment_mode')}
						disabled={formState.payment_mode.disabled}
						required={formState.payment_mode.required}
						errorMap={{
							valueMissing: `${strings('payment mode is required')}`,
						}}
					/>
				</InputWrapper>
				<ItemWrapper>
					<Button disabled={false} onClick={() => buttonHandler()}>
						Update
					</Button>
				</ItemWrapper>
			</FormItemWrapper>
		</DrawerBodyWrapper>
	)
}

export default PaymentModeForm
