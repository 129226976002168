import { Button, DrawerBodyWrapper, DrawerFooterWrapper, MultiSelect, TOAST_TYPE } from '@base'
import { strings } from '@constants'
import { UpdateFinancialsBusinessDetail, getCategories } from '@data'
import { showLoader, showToast } from '@data/state/action'
import { toggleDrawer } from '@data/state/action/root'
import { Spacings } from '@styles'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

const InputWrapper = styled.div`
	padding: ${Spacings.SPACING_1B} 0;

	& > div {
		width: 100%;
	}
`

const getFormState = (data, categories = null) => {
	const _state = {
		business_types: {
			id: 'business_types',
			label: strings(['type', 'of', 'business']),
			placeholder: strings(['select', 'type', 'of', 'business']),
			data: [],
			primaryKey: 'title',
			displayKey: 'title',
			value: '',
			required: true,
			disabled: false,
		},
		projects_key: {
			id: 'projects_key',
			label: strings(['key', 'projects']),
			placeholder: strings(['select', 'key', 'projects']),
			data: [],
			primaryKey: 'title',
			displayKey: 'title',
			value: '',
			required: true,
			disabled: false,
		},
		projects_completed: {
			id: 'projects_completed',
			label: strings(['completed', 'projects']),
			placeholder: strings(['select', 'completed', 'projects']),
			data: [],
			primaryKey: 'title',
			displayKey: 'title',
			value: '',
			required: true,
			disabled: false,
		},
		projects_mbook: {
			id: 'projects_mbook',
			label: strings(['mbook', 'projects']),
			placeholder: strings(['select', 'mbook', 'projects']),
			data: [],
			primaryKey: 'title',
			displayKey: 'title',
			value: '',
			required: true,
			disabled: false,
		},
		departments: {
			id: 'departments',
			label: strings(['department', 'list']),
			placeholder: strings(['select', 'department', 'list']),
			data: [],
			primaryKey: 'title',
			displayKey: 'title',
			value: '',
			required: true,
			disabled: false,
		},
		materials: {
			id: 'materials',
			label: strings('materials'),
			placeholder: strings(['select', 'materials']),
			data: [],
			primaryKey: 'category_id',
			displayKey: 'category_name',
			value: '',
			required: true,
			disabled: false,
		},
	}

	if (data) {
		_state.materials.value = categories?.filter((item) => data?.materials?.includes(item?.category_name))
		_state.departments.value = data?.departments?.length
			? data?.departments?.map((item) => ({ primaryKey: 'title', displayKey: 'title', title: item }))
			: ''
		_state.projects_mbook.value = data?.projects_mbook?.length
			? data?.projects_mbook?.map((item) => ({ primaryKey: 'title', displayKey: 'title', title: item }))
			: ''
		_state.projects_completed.value = data?.projects_completed?.length
			? data?.projects_completed?.map((item) => ({ primaryKey: 'title', displayKey: 'title', title: item }))
			: ''
		_state.projects_key.value = data?.projects_key?.length
			? data?.projects_key?.map((item) => ({ primaryKey: 'title', displayKey: 'title', title: item }))
			: ''
		_state.business_types.value = data?.business_types?.length
			? data?.business_types?.map((item) => ({ primaryKey: 'title', displayKey: 'title', title: item }))
			: ''
	}

	return _state
}

const BusinessDetailsForm = ({ data, userDetails, organisationId, update }) => {
	const dispatch = useDispatch()
	const [formState, setFormState] = useState(getFormState())
	const [categories, setCategories] = useState()

	useEffect(() => {
		getCategoryList()
	}, [])

	const getCategoryList = () => {
		getCategories(0)
			.then((response) => {
				setCategories(response.data)
			})
			.catch((err) => console.log(err))
	}

	useEffect(() => {
		setFormState(getFormState(data, categories))
	}, [data, categories])

	const buttonHandler = () => {
		dispatch(showLoader(true))
		const business_types = formState.business_types?.value ? formState.business_types?.value?.map((item) => item?.title) : ''
		const projects_key = formState.projects_key?.value ? formState.projects_key?.value?.map((item) => item?.title) : ''
		const projects_completed = formState.projects_completed?.value ? formState.projects_completed?.value?.map((item) => item?.title) : ''
		const projects_mbook = formState.projects_mbook?.value ? formState.projects_mbook?.value?.map((item) => item?.title) : ''
		const materials = formState.materials?.value ? formState.materials?.value?.map((item) => item?.category_name) : ''
		const departments = formState.departments?.value ? formState.departments?.value?.map((item) => item?.title) : ''

		UpdateFinancialsBusinessDetail(
			business_types,
			projects_key,
			projects_completed,
			projects_mbook,
			materials,
			departments,
			userDetails,
			organisationId
		)
			.then((res) => {
				dispatch(showToast(true, strings(['business', 'details', 'updated', 'successfully']), { type: TOAST_TYPE.SUCCESS }))
				update()
			})
			.catch((err) => {
				dispatch(showToast(true, strings('error_something_went_wrong'), { type: TOAST_TYPE.ERROR }))
			})
			.finally(() => {
				dispatch(showLoader(false))
			})
	}

	const cancelHandler = () => {
		dispatch(toggleDrawer(false))
	}

	const updateState = (key) => (value) => {
		setFormState((_s) => {
			return {
				..._s,
				[key]: {
					..._s[key],
					value,
				},
			}
		})
	}
	const isDisabled = false

	return (
		<>
			<DrawerBodyWrapper>
				<InputWrapper>
					<MultiSelect
						id={formState.business_types.id}
						label={formState.business_types.label}
						data={[]}
						placeholder={formState.business_types.placeholder}
						primaryKey={formState.business_types.primaryKey}
						displayKey={formState.business_types.displayKey}
						value={formState.business_types?.value?.length > 0 ? formState.business_types?.value : []}
						required={formState.business_types.required}
						onChange={updateState('business_types')}
						errorMap={{
							valueMissing: `${strings('Type of business is required')}`,
						}}
						createNewOption={true}
					/>
				</InputWrapper>
				<InputWrapper>
					<MultiSelect
						id={formState.projects_key.id}
						label={formState.projects_key.label}
						data={formState.projects_key.data}
						placeholder={formState.projects_key.placeholder}
						primaryKey={formState.projects_key.primaryKey}
						displayKey={formState.projects_key.displayKey}
						value={formState.projects_key?.value || []}
						required={formState.projects_key.required}
						onChange={updateState('projects_key')}
						errorMap={{
							valueMissing: `${strings('Key projects is required')}`,
						}}
						createNewOption={true}
					/>
				</InputWrapper>
				<InputWrapper>
					<MultiSelect
						id={formState.projects_completed.id}
						label={formState.projects_completed.label}
						data={formState.projects_completed.data}
						placeholder={formState.projects_completed.placeholder}
						primaryKey={formState.projects_completed.primaryKey}
						displayKey={formState.projects_completed.displayKey}
						value={formState.projects_completed?.value || []}
						required={formState.projects_completed.required}
						onChange={updateState('projects_completed')}
						errorMap={{
							valueMissing: `${strings('Completed projects is required')}`,
						}}
						createNewOption={true}
					/>
				</InputWrapper>
				<InputWrapper>
					<MultiSelect
						id={formState.projects_mbook.id}
						label={formState.projects_mbook.label}
						data={formState.projects_mbook.data}
						placeholder={formState.projects_mbook.placeholder}
						primaryKey={formState.projects_mbook.primaryKey}
						displayKey={formState.projects_mbook.displayKey}
						value={formState.projects_mbook?.value || []}
						required={formState.projects_mbook.required}
						onChange={updateState('projects_mbook')}
						errorMap={{
							valueMissing: `${strings('Mbook projects is required')}`,
						}}
						createNewOption={true}
					/>
				</InputWrapper>
				<InputWrapper>
					<MultiSelect
						id={formState.departments.id}
						label={formState.departments.label}
						data={formState.departments.data}
						placeholder={formState.departments.placeholder}
						primaryKey={formState.departments.primaryKey}
						displayKey={formState.departments.displayKey}
						value={formState.departments?.value || []}
						required={formState.departments.required}
						onChange={updateState('departments')}
						errorMap={{
							valueMissing: `${strings('Department list is required')}`,
						}}
						createNewOption={true}
					/>
				</InputWrapper>
				<InputWrapper>
					<MultiSelect
						id={formState.materials.id}
						label={formState.materials.label}
						data={categories}
						placeholder={!categories ? 'Loading...' : formState.materials.placeholder}
						primaryKey={formState.materials.primaryKey}
						displayKey={formState.materials.displayKey}
						value={formState.materials?.value || []}
						required={formState.materials.required}
						onChange={updateState('materials')}
						errorMap={{
							valueMissing: `${strings('Materials is required')}`,
						}}
						createNewOption={true}
					/>
				</InputWrapper>
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button small uppercase type='primary' disabled={isDisabled} onClick={buttonHandler}>
					{data ? strings(['edit']) : strings(['add'])}
				</Button>
				<Button margin={`0 auto 0 0`} small uppercase type='secondary' variant='destructive' onClick={cancelHandler}>
					{strings('cancel')}
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default BusinessDetailsForm
