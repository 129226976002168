import API from '../api'
import putUrl from './paths'

export const addAddressToOrganisation = (address, orgId) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.addAddressToOrganisation, address, { orgId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const addGstToOrganisation = (gstDetail, orgId) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.addGstToOrganisation, gstDetail, { orgId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const updateDefaultAdmin = (orgId, userId) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.updateDefaultAdmin, { user_id: userId }, { orgId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const requestOrgKycUpdate = (kycRequested, orgId) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.requestKycUpdate, {}, { kycRequested, orgId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const acceptRoles = (organisationId, userId, roles) =>
	new Promise((resolve, reject) =>
		API.put(putUrl.acceptRoles, roles, { organisationId, userId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	)

export const requestRoles = (organisationId, roles) =>
	new Promise((resolve, reject) =>
		API.put(putUrl.requestRoles, roles, { organisationId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	)

export const removeRoleFromOrganisation = (orgId, platform, role) =>
	new Promise((resolve, reject) =>
		API.put(putUrl.removeRoleFromOrganisation, {}, { orgId, platform, role })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	)

export const mergeOrganisation = (fromOrgId, toOrgId) =>
	new Promise((resolve, reject) =>
		API.put(putUrl.mergeOrganisation, {}, { fromOrgId, toOrgId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	)

export const fetchCompanyData = (organisationId) =>
	new Promise((resolve, reject) =>
		API.put(putUrl.fetchCompanyData, {}, { organisationId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	)

export const updateIsTrader = (isTrader, organisationId) =>
	new Promise((resolve, reject) =>
		API.put(putUrl.updateIsTrader, {}, { isTrader, organisationId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	)

export const updateRegion = (region, organisationId) =>
	new Promise((resolve, reject) =>
		API.put(putUrl.updateRegion, {}, { region, organisationId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	)

export const updateAccountDetails = (organisationId, accountDetails) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.updateAccountDetails, accountDetails, { organisationId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const updateOrganisationApi = (organisationId, type, value = null, obj = {}) =>
	// type == ORG_NAME, PAN, UPDATE_SALES_MANAGER, MBOOK_BANK
	new Promise((resolve, reject) => {
		API.put(putUrl.updateOrganisation, { ...obj}, { organisationId, type, value })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
