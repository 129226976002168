import API from '../api'
import getUrl from './paths'

export const generateReport = (organisationId) =>
	new Promise((resolve, reject) => {
		API.get(getUrl.generateReport, { organisationId, removeDefault: true })
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})
