import API from '../api'
import postUrl from './paths'

export const createCategory = (category) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.addCategory, category)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const createProduct = (product) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.addProduct, product)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})
export const createBrandApi = (brand) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.addBrand, brand)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const weightConvertorApi = (categoryId, length, pieces, attributes = null) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.weightConvertor, { ...attributes }, { categoryId, length, pieces })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})


