import React, { useEffect, useMemo, useState } from 'react'
import { DetailsWrapper, GridListWrapper } from '../../styles'
import { PageLevelTableWrapper, SectionHeader, Table } from '@base'
import { strings } from '@constants'
import { displayAmount, showDateFormat } from '@utils'
import { getCreditLimitByOrdId } from '@data/conn/get/creditLimit'

const CreditLimitList = ({ orgData,forceUpdate }) => {
	const [creditLimitList, setCreditLimitList] = useState([])

	useEffect(() => {
		orgData?.organisation_id &&
			getCreditLimitByOrdId(orgData?.organisation_id)
				.then((response) => {
					setCreditLimitList(response)
				})
				.catch((error) => {
					console.log(error)
				})
	}, [orgData,forceUpdate])

	const columns = useMemo(() => {
		return [
			{
				label: strings('status'),
				key: (item) => {
					return item?.status ? item?.status : '-'
				},
				mobile: true,
			},
			{
				label: strings('amount'),
				key: (item) => {
					return item?.amount ? displayAmount(item?.amount) : '-'
				},
				mobile: true,
			},
			{
				label: strings('sanction_date'),
				key: (item) => {
					return item?.sanction_date ? showDateFormat(new Date(item?.sanction_date)) : '-'
				},
				mobile: true,
			},
			{
				label: strings('expiry_date'),
				key: (item) => {
					return item?.expiry_date ? showDateFormat(new Date(item?.expiry_date)) : '-'
				},
				mobile: true,
			},
		]
	}, [])

	return (
		<div>
			<DetailsWrapper>
				<SectionHeader>{strings(['credit', 'limit', 'list'])}</SectionHeader>
				<GridListWrapper itemPerRow={[1, 1, 1]}>
					<PageLevelTableWrapper>
						<Table fullHeight data={creditLimitList} columns={columns} page={0} hideFooter rowClickable={false} />
					</PageLevelTableWrapper>
				</GridListWrapper>
			</DetailsWrapper>
		</div>
	)
}

export default CreditLimitList
