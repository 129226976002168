import API from '../api'
import putUrl from './paths'

export const UpdateFinancialsBusinessDetail = (
	business_types,
	projects_key,
	projects_completed,
	projects_mbook,
	materials,
	departments,
	user_details,
	organisationId
) =>
	new Promise((resolve, reject) => {
		API.put(
			putUrl.UpdateFinancialsBusinessDetail,
			{ business_types, projects_key, projects_completed, projects_mbook, materials, departments, user_details },
			{ organisationId }
		)
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})

export const editPdc = (pdc, organisationId) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.editPdc, pdc, { organisationId })
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})

export const setInsuredLimit = (insuredLimit, organisationId) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.setInsuredLimit, {}, { insuredLimit, organisationId })
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})

export const addFinancialsRemark = (organisationId, remark_by, type, remark, created_at) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.addFinancialsRemark, { remark_by, type, remark, created_at }, { organisationId })
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})

export const setFinancialsFeedback = (feedbackType, financialsId, score, comments, team_comment, feedback_by, score_by = null) =>
	new Promise((resolve, reject) => {
		API.put(putUrl.setFinancialsFeedback, { score, comments, team_comment, feedback_by, score_by }, { feedbackType, financialsId })
			.then((response) => {
				resolve(response)
			})
			.catch((err) => reject(err))
	})
