import React from 'react'
import { DRAWER_TYPES, strings } from '@constants'
import { DetailsWrapper, EditIconWrapper, GridListWrapper, Label, LabelWrapper, MenuIcon, TextValue } from '../../styles'
import { SectionHeader } from '@base'
import { Edit } from '@material-ui/icons'
import { toggleDrawer } from '@data/state/action/root'
import { useDispatch } from 'react-redux'

const AdhocJourney = ({ orgData, financialData, update }) => {
	const dispatch = useDispatch()

	const orgPanChangeHandler = () => {
		dispatch(
			toggleDrawer(true, DRAWER_TYPES.ADHOC_EDIT_INSURER_CREDIT, {
				label: 'Edit Insurer Credit Limit',
				update,
				organisationId: orgData?.organisation_id,
				insured_amount: financialData?.insured_amount,
			})
		)
	}
	return (
		<>
			<DetailsWrapper>
				<SectionHeader>{strings('credit', 'limit')}</SectionHeader>
				<GridListWrapper itemPerRow={[2, 3, 3]}>
					<div>
						<LabelWrapper>
							<Label>{strings('receivables')}</Label>
						</LabelWrapper>
						<TextValue>{financialData?.receivable ?? '--'}</TextValue>
					</div>
					<div>
						<LabelWrapper>
							<Label>{strings(['live', 'adhoc', 'limit'])}</Label>
						</LabelWrapper>
						<TextValue>{financialData?.live_limit ?? '--'}</TextValue>
					</div>
					<div>
						<LabelWrapper>
							<Label>{strings('sanctioned', 'limit')}</Label>
						</LabelWrapper>
						<TextValue>{financialData?.sanctioned_limit ?? '--'}</TextValue>
					</div>
					<div>
						<LabelWrapper>
							<Label>
								{strings(['Insurer', 'credit', 'limit'])}
								<EditIconWrapper type='secondary' small uppercase onClick={() => orgPanChangeHandler()}>
									<MenuIcon>
										<Edit fontSize='small' />
									</MenuIcon>
								</EditIconWrapper>
							</Label>
						</LabelWrapper>
						<TextValue>{financialData?.insured_amount ?? '--'}</TextValue>
					</div>
					<div>
						<LabelWrapper>
							<Label>{strings(['last', 'sanctioned', 'date'])}</Label>
						</LabelWrapper>
						<TextValue>{financialData?.last_sanctioned_date ?? '--'}</TextValue>
					</div>
					<div>
						<LabelWrapper>
							<Label>{strings('payment', 'terms')}</Label>
						</LabelWrapper>
						<TextValue>{financialData?.payment_terms_label ?? '--'}</TextValue>
					</div>
				</GridListWrapper>
			</DetailsWrapper>
		</>
	)
}

export default AdhocJourney
