import API from '../api'
import postUrl from './paths'

export const uploadCran = (file, organisationId) =>
	new Promise((resolve, reject) => {
		const formData = new FormData()
		formData.append('file', file)

		API.post(postUrl.uploadCran, formData, { organisationId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const addPdc = (pdc, organisationId) =>
	new Promise((resolve, reject) => {
		API.post(postUrl.addPdc, pdc, { organisationId })
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => reject(err))
	})

export const uploadPdcChequeImage = (file) =>
	new Promise((resolve, reject) => {
		const formData = new FormData();
		formData.append('file', file);
		API.post(postUrl.uploadPdcChequeImage, formData)
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => {
				reject(error);
			})
	})