import { brands } from '@constants'
import { editEnquiry, getCategories } from '@data'
import { showLoader } from '@data/state/action'
import { createEnquiry as createEnquiryAction } from '@data'

export const ADD_NEW_ITEM_TO_ENQUIRY = 'ADD_NEW_ITEM_TO_ENQUIRY'
export const CREATE_NEW_ENQUIRY = 'CREATE_NEW_ENQUIRY'
export const CREATE_NEW_ENQUIRY_FROM_DATA = 'CREATE_NEW_ENQUIRY_FROM_DATA'
export const UPDATE_NEW_ENQUIRY = 'UPDATE_NEW_ENQUIRY'
export const UPDATE_ENQUIRY_ITEM = 'UPDATE_ENQUIRY_ITEM'
export const DELETE_ENQUIRY_ITEM = 'DELETE_ENQUIRY_ITEM'

export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS'
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE'
export const FETCH_CATEGORIES_PROGRESS = 'FETCH_CATEGORIES_PROGRESS'

export const FETCH_BRANDS_SUCCESS = 'FETCH_BRANDS_SUCCESS'
export const FETCH_BRANDS_FAILURE = 'FETCH_BRANDS_FAILURE'
export const FETCH_BRANDS_PROGRESS = 'FETCH_BRANDS_PROGRESS'

export const CREATE_ENQUIRY_SUCCESS = 'CREATE_ENQUIRY_SUCCESS'
export const CREATE_ENQUIRY_FAILURE = 'CREATE_ENQUIRY_FAILURE'
export const CREATE_ENQUIRY_PROGRESS = 'CREATE_ENQUIRY_PROGRESS'

export const UPDATE_ENQUIRY_SUCCESS = 'UPDATE_ENQUIRY_SUCCESS'
export const UPDATE_ENQUIRY_FAILURE = 'UPDATE_ENQUIRY_FAILURE'
export const UPDATE_ENQUIRY_PROGRESS = 'UPDATE_ENQUIRY_PROGRESS'

export const CLEAR_NEW_ENQUIRY = 'CLEAR_NEW_ENQUIRY'

export const addNewItemToEnquiry = (payload) => ({
	type: ADD_NEW_ITEM_TO_ENQUIRY,
	payload,
})
export const createNewEnquiry = (user) => ({
	type: CREATE_NEW_ENQUIRY,
	user,
})
export const createNewEnquiryFromData = (data) => ({
	type: CREATE_NEW_ENQUIRY_FROM_DATA,
	data,
})
export const updateNewEnquiry = (payload) => ({
	type: UPDATE_NEW_ENQUIRY,
	payload,
})

export const updateEnquiryItem = (itemId, payload) => ({
	type: UPDATE_ENQUIRY_ITEM,
	itemId,
	payload,
})

export const deleteEnquiryItem = (itemId) => ({
	type: DELETE_ENQUIRY_ITEM,
	itemId,
})

export const clearNewEnquiryState = () => ({
	type: CLEAR_NEW_ENQUIRY,
})

// thunk actions

export const fetchCategories =
	(commodityFilter = null, page = 0) =>
	(dispatch) => {
		dispatch({ type: FETCH_CATEGORIES_PROGRESS })
		return getCategories(page, 1000, commodityFilter)
			.then((response) => {
				dispatch({ type: FETCH_CATEGORIES_SUCCESS, payload: response })
			})
			.catch((err) => {
				dispatch({ type: FETCH_CATEGORIES_FAILURE, error: err })
			})
	}

export const fetchBrands = () => (dispatch) => {
	dispatch({ type: FETCH_BRANDS_PROGRESS })

	return dispatch({
		type: FETCH_BRANDS_SUCCESS,
		payload: brands.map((brand) => ({
			name: brand,
			id: brand.split(' ').join('_').toLowerCase(),
		})),
	})
}

export const createEnquiry =
	(data, newAddressAdded = false, globalDispatch, user) =>
	(dispatch) => {
		globalDispatch(showLoader(true))
		dispatch({ type: CREATE_ENQUIRY_PROGRESS })
		return (
			createEnquiryAction(data, user, newAddressAdded)
				// return API.post(postUrl.createEnquiry, data, { newAddressAdded })
				.then((data) => {
					dispatch({
						type: CREATE_ENQUIRY_SUCCESS,
						data,
					})
				})
				.catch((err) => {
					dispatch({
						type: CREATE_ENQUIRY_FAILURE,
						error: err,
					})
				})
				.finally(() => {
					globalDispatch(showLoader(false))
				})
		)
	}

export const updateEnquiry = (data, globalDispatch, user) => (dispatch) => {
	globalDispatch(showLoader(true))
	dispatch({ type: UPDATE_ENQUIRY_PROGRESS })
	return (
		editEnquiry({ enquiry: data, user })
			// return API.put(putUrl.editEnquiry, data)
			.then((data) => {
				dispatch({
					type: UPDATE_ENQUIRY_SUCCESS,
					data,
				})
			})
			.catch((err) => {
				dispatch({
					type: UPDATE_ENQUIRY_FAILURE,
					error: err,
				})
			})
			.finally(() => {
				globalDispatch(showLoader(false))
			})
	)
}
