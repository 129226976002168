import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { MultiSelect, Textarea } from '@base'
import { Spacings } from '@styles'

const FormWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	border-radius: ${Spacings.SPACING_2B};
`

const SectionItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	height: 100%;
	${({ isBottom }) => {
		if (isBottom) {
			return css`
				border-bottom: 2px solid #f4f5f7;
				padding: 0px 0px 10px 0px;
			`
		}
	}}
	margin-top: ${Spacings.SPACING_2B};

	& > div {
		width: 100%;
	}
`

const ShareEnquiryForm = React.memo(({ state, updateState, associatedSuppliers, nonAssociatedSuppliers, _Admins }) => {
	const {
		associated_suppliers,
		non_associated_suppliers,
		buyer_message,
		associated_supplier_message,
		non_associated_supplier_message,
		admins,
		admin_message,
	} = state
	const onChange = (key) => (value) => {
		updateState(key, { value, dirty: true })
	}

	return (
		<FormWrapper>
			<SectionItemWrapper isBottom>
				<Textarea
					rows={2}
					label={buyer_message?.label}
					disabled={buyer_message?.disabled}
					placeholder={buyer_message?.placeholder}
					value={buyer_message?.value}
					onChange={onChange('buyer_message')}
				/>
			</SectionItemWrapper>
			<SectionItemWrapper>
				<MultiSelect
					id='enquiry-associated-suppliers-select'
					disabled={associated_suppliers?.disabled}
					label={associated_suppliers?.label}
					displayKey={associated_suppliers?.displayKey}
					primaryKey={associated_suppliers?.primaryKey}
					placeholder={associated_suppliers?.placeholder}
					data={associatedSuppliers}
					value={associated_suppliers?.value}
					onChange={onChange('associated_suppliers')}
					multiple
				/>
			</SectionItemWrapper>
			<SectionItemWrapper isBottom>
				<Textarea
					rows={2}
					label={associated_supplier_message?.label}
					disabled={associated_supplier_message?.disabled}
					placeholder={associated_supplier_message?.placeholder}
					value={associated_supplier_message?.value}
					onChange={onChange('associated_supplier_message')}
				/>
			</SectionItemWrapper>
			<SectionItemWrapper>
				<MultiSelect
					id='enquiry-non-associated-suppliers-select'
					disabled={non_associated_suppliers?.disabled}
					label={non_associated_suppliers?.label}
					displayKey={non_associated_suppliers?.displayKey}
					primaryKey={non_associated_suppliers?.primaryKey}
					placeholder={non_associated_suppliers?.placeholder}
					data={nonAssociatedSuppliers}
					value={non_associated_suppliers?.value}
					onChange={onChange('non_associated_suppliers')}
				/>
			</SectionItemWrapper>
			<SectionItemWrapper>
				<Textarea
					rows={2}
					label={non_associated_supplier_message?.label}
					disabled={non_associated_supplier_message?.disabled}
					placeholder={non_associated_supplier_message?.placeholder}
					value={non_associated_supplier_message?.value}
					onChange={onChange('non_associated_supplier_message')}
				/>
			</SectionItemWrapper>
			<SectionItemWrapper>
				<MultiSelect
					id='enquiry-admins-select'
					disabled={admins?.disabled}
					label={admins?.label}
					displayKey={admins?.displayKey}
					primaryKey={admins?.primaryKey}
					placeholder={admins?.placeholder}
					data={_Admins}
					value={admins?.value}
					onChange={onChange('admins')}
				/>
			</SectionItemWrapper>
			<SectionItemWrapper>
				<Textarea
					rows={2}
					label={admin_message?.label}
					disabled={admin_message?.disabled}
					placeholder={admin_message?.placeholder}
					value={admin_message?.value}
					onChange={onChange('admin_message')}
				/>
			</SectionItemWrapper>
		</FormWrapper>
	)
})

export default ShareEnquiryForm
